import AccessTypeSelector from "../access/component/AccessTypeSelector";
import DeviceSelector from "../access/component/DeviceSelector";
import PersonSelector from "../person/component/PersonSelector";
import {DateRangeColumnFilter, SelectColumnFilter} from "../../components/table/TableFilter";
import {dayMonthYearAtTimeWithSeconds} from "../../shared/utils/dates";
import {formatByIdAndMatch, ROUTE_PATH} from "../../shared/routes/RoutesPath";
import {Link} from "react-router-dom";

const InsidePersonsListSchema = () => {
  const endpoint = 'gtt-access-control/v1/access-events/inside-persons';
  return {
    getEndpoint: () => endpoint,

    title: 'Pessoas no Recinto',


    getHeader: () => {
      return {
        showNewButton: false
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Documento / Pessoas",
          accessor: "person.id",
          Filter:false,
          CustomSelector: PersonSelector,
          Cell: ({row}) => row.original.person ? <Link to={formatByIdAndMatch({id: row.original.person.id}, ROUTE_PATH.PERSON_PROFILE_PAGE)}>{row.original.person.identificationDocument.id + ' - '
            + row.original.person.identificationDocument.value + ' - ' + row.original.person.name}</Link> : "Não identificado"
        },
        {
          Header: "Data do acesso",
          accessor: "intervalDate",
          width: 70,
          minWidth: 70,
          maxWidth: 70,
          Filter:false,
          Cell: ({row}) => dayMonthYearAtTimeWithSeconds(row.original?.createdAt)
        },
      ]
    }
  }
}

export default InsidePersonsListSchema;
