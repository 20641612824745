import {Button, Col, Row} from "react-bootstrap";
import {ReactTable} from "../../../components";
import React, {useCallback, useEffect, useState} from "react";
import {doHttpGetListTable} from "../ListHttpService";
import Notify from "../../../shared/common/notify/Notify";
import {useHistory} from "react-router-dom";
import ExportData from "../../../shared/utils/ExportData";

const defaultState = {
  content: [],
  pageCount: 0,
};
const CustomTable = ({entitySchema}) => {

  const [data, setData] = useState(defaultState);
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [tableFilter, setTableFilter] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const cols = entitySchema.getColumns()
    setColumns(cols)
    setData(defaultState);
  }, [entitySchema]);

  const generatePDF = useCallback(async (filters) => {
    try {
      setIsLoading(true);
      const x =  {
        filters: filters,
        pageSize: "100000",
        pageIndex: "0",
        sortBy: [{desc:true, id:"createdAt"}]
      }
      const columnsPDF = entitySchema.getColumnsPDF();
      const data = await doHttpGetListTable(entitySchema,x,{history});
      ExportData.exportToPDF(data.content,columnsPDF,"Relatório de Acessos")
    } catch (e) {
      Notify.error('Falha ao carregar dados', e);
    } finally {
      setIsLoading(false);
    }
  }, [entitySchema]);

  const fetchDate = useCallback(async (props) => {
    try {
      setIsLoading(true);
      const data = await doHttpGetListTable(entitySchema, props, {history});
      if (data) {
        setData(data);
      } else {
        setData(defaultState);
      }
    } catch (e) {
      Notify.error('Falha ao carregar dados', e);
    } finally {
      setIsLoading(false);
    }
  }, [entitySchema]);

  return (
    <Row>
      <Col>
        {entitySchema.showPDFButton && 
        <Button onClick={() => generatePDF(tableFilter)} className="px-2 py-2 float-right d-flex"><i className="far fa-file-pdf"> Gerar PDF</i>
        </Button>}
      </Col>
      <Col sm={12} md={12}>
        <ReactTable
          pageCount={data.pageCount}
          loading={isLoading}
          onFiltersChange={setTableFilter}
          generatePDF={generatePDF}
          fetchData={fetchDate}
          data={data.content}
          tableColumns={columns}
          renderRowSubComponent={entitySchema.renderRowSubComponent}
        />
      </Col>
    </Row>);
}

export default CustomTable;
