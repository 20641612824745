import {dayMonthYearAtTimeWithSeconds} from "../../../shared/utils/dates";
import {DateRangeColumnFilter, SelectColumnFilter} from "../../../components/table/TableFilter";
import AccessTypeSelector from "../../access/component/AccessTypeSelector";
import {AccessTypeMessage, AccessTypeMessageGrid} from "../../access-events/AccessEventsListSchema";
import {Link} from "react-router-dom";

const PersonEventList = (personId) => {
  const endpoint = 'gtt-access-control/v1/access-events';
  return {
    getEndpoint: () => `${endpoint}?person.id=${personId}&`,

    showPDFButton: true,

    getColumnsPDF: () => {
      return [
        {
          Header: "Data",
          accessor: "intervalDate",
          Filter: DateRangeColumnFilter,
          renderRow: (item) => dayMonthYearAtTimeWithSeconds(item?.createdAt)
        },
        {
          Header: "Documento / Pessoas",
          accessor: "person.id",
          Filter: false,
          renderRow: (item) => item?.person ? (item?.person.identificationDocument.id + ' - '
            + item?.person.identificationDocument.value + ' - ' + item?.person.name) : "Não identificado"
        },
        {
          Header: "Tipo de Acesso",
          accessor: "accessType",
          urlValue: "accessEvent",
          Filter: SelectColumnFilter,
          CustomSelector: AccessTypeSelector,
          renderRow: (item) => AccessTypeMessage(item)
        },
        {
          Header: "Local de Acesso",
          accessor: "device.id",
          Filter: false,
          renderRow: (item) => item.device ? item.device.name : ''
        }
      ]
    },

    getColumns: () => {
      return [
        {
          Header: "Data",
          accessor: "intervalDate",
          width: 80,
          minWidth: 80,
          maxWidth: 80,
          Filter: DateRangeColumnFilter,
          Cell: ({row}) => dayMonthYearAtTimeWithSeconds(row.original?.createdAt)
        },
        {
          Header: "Documento / Pessoas",
          accessor: "person.id",
          Filter: false,
          Cell: ({row}) => row.original.person ? (row.original.person.identificationDocument.id + ' - '
            + row.original.person.identificationDocument.value + ' - ' + row.original.person.name) : "Não identificado"
        },
        {
          Header: "Tipo de Acesso",
          accessor: "accessType",
          urlValue: "accessEvent",
          width: 80,
          minWidth: 80,
          maxWidth: 80,
          Filter: SelectColumnFilter,
          CustomSelector: AccessTypeSelector,
          Cell: ({row}) => row.original.type === 'VEHICLE'? <a href="/scg/contingent">{AccessTypeMessageGrid(row)}</a> : AccessTypeMessageGrid(row)
        },
        {
          Header: "Local de Acesso",
          accessor: "device.id",
          Filter: false,
          Cell: ({row}) => row.original.device?.name || ''
        },
      ]
    }
  }
}

export default PersonEventList;
