import {BaseSelectorReadonlyToInputV2} from "../../../components";
import {AccessTypeMessage} from "../../access-events/AccessEventsListSchema";

export const ACCESS_TYPES = [
  {id: "GRANTED", name: "Permitido"},
  {id: "IN_PROCESS", name: 'Aguardando dados do veículo'},
  {id: "DENIED", name: 'Negado'}
];
const AccessTypeSelector = ({
  required = false,
  defaultValue,
  title = "Tipo de usuário",
  setValue = ACCESS_TYPES,
  ...props
}) => {

  return <BaseSelectorReadonlyToInputV2
    title={title}
    required={required}
    label="name"
    setValue={setValue}
    showCreateButton={false}
    items={ACCESS_TYPES}
    {...props}
  />

}

export default AccessTypeSelector;
