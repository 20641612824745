import {useState} from "react";
import {LoadingButton} from "../../../components/loading/LoadingButton";


export const AntiPassbackButton = ({
  onClick,
  text = 'Desbloquear o Anti Passback',
  title = 'Desbloquear o Anti Passback',
  variant = 'danger',
}) => {

  const [isLoading, setIsLoading] = useState(false);

  const _onClick = async (e) => {
    setIsLoading(true);
    onClick && await onClick(e);
  }

  return (<LoadingButton size="md" onClick={_onClick} isLoading={isLoading}
                         title={title} variantButton={variant}
                         text={text}/>)
}
