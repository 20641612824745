import PDFUtils from "./PDFUtils";

export default class ExportData {

  static exportToPDF(data, columns,title) {
    const pdf = PDFUtils.convertArrayOfObjectsToPDF({data, columns: columns.filter(x => x.export !== false), title});
    if (pdf == null) {
      return;
    }
    const dataText = 'data:application/pdf;charset=utf-8,' + pdf;
    const url = encodeURI(dataText);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.click();

  }
}